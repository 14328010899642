import React, { useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "./AuthProvider";
import SignInForm from "../../components/Auth/SignInForm/SignInForm";

const backendUrl = "https://omelive.online";

function SignIn() {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const [user, setUser] = useState({ email: "", password: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const handleSignIn = async (e) => {
    e.preventDefault();
   
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    
    const response = await axios.post(`${backendUrl}/api/auth/signin`, user, {
      withCredentials: true,
    });
    
    console.log('Axios response:', response);
    console.log('Axios response status:', response.status);
    console.log('Axios response data:', response.data);
    if (response && response.status === 200) {
      toast.success("Signed In Successfully!");
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
      setIsSubmitting(false);
      return; // Ensure no further code is executed
    }
    
    if (response && response.status === 400) {
      toast.error("Error signing; check email and password.");
      setIsSubmitting(false);
      return;
    }
    
    toast.error("An unexpected error occurred. Please try again.");
    setIsSubmitting(false);
  };
  
  
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-violet-500 to-cyan-300">
      <SignInForm
        user={user}
        setUser={setUser}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        onSubmit={handleSignIn}
      />
    </div>
  );
}

export default SignIn;
